<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Add New Plot
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Admins</a></li>
          <li class="breadcrumb-item active" aria-current="page">New</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ValidationProvider name="plan" rules="required">
              <b-form-group slot-scope="{ errors }" label-for="plans" horizontal label="Plan">
                <b-form-select id="plans" :options="plans"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="classification" rules="required">
              <b-form-group slot-scope="{ errors }" label-for="classification" horizontal label="Classification">
                <b-form-select id="classification" @change="switchSubClass" v-model="dataClassification" :options="classifications"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="sub_classification" rules="required">
              <b-form-group slot-scope="{ errors }" label-for="sub_classification" horizontal label="Sub Classification">
                <b-form-select id="sub_classification" :disabled="!subClassifications.length" v-model="dataSubClassification" :options="subClassifications"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
             <ValidationProvider name="longitude" rules="required">
              <b-form-group slot-scope="{ errors }" label="Longitude" label-for="longitude">
                <b-form-input type="text" id="longitude" v-model="longitude" placeholder="Longitude"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="latitude" rules="required">
               <b-form-group slot-scope="{ errors }" label="Latitude" label-for="longitude">
                 <b-form-input type="text" id="latitude" v-model="latitude" placeholder="Latitude"></b-form-input>
                 <p>{{ errors[0] }}</p>
               </b-form-group>
               </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ValidationProvider name="plot" rules="required">
              <b-form-group slot-scope="{ errors }" label="Plot"  label-for="plot">
                <b-form-input type="text" id="plot" v-model="plot" placeholder="Plot"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="block" rules="required">
              <b-form-group slot-scope="{ errors }" label="Block"  label-for="block">
                <b-form-input type="text" id="block" v-model="block" placeholder="Block"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="meter_sq" rules="required">
              <b-form-group slot-scope="{ errors }" label="Meter Square"  label-for="meter_sq">
                <b-form-input type="text" id="meter_sq" v-model="meter_sq" placeholder="Meter Square"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'adminAddNew',
  data () {
    return {
      neighborhood: "",
      plot: "",
      block: "",
      meter_sq: "",
      longitude: "",
      latitude: "",
      dataClassification: "",
      dataSubClassification: "",
      create_text: "Add Plot",
      plans: [
        {value: 1484, text: "مخطط/2022"},
        {value: 1485, text: "مخطط/2485"}
      ],
      classifications: [
        {value: "", text: "Select Classification"},
        {value: "Commercial", text: "Commercial"},
        {value: "Residential", text: "Residential"},
      ],
      subClassifications: []
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    currentPage() {
      return this.$store.state.plot.sub_classification_meta.current_page
    },
    paginationPageSize() {
      return this.$store.state.plot.sub_classification_meta.paginate
    },
    listSubClassifications() {
      return this.$store.getters['plot/listSubClassification']
    },
    commercialClassifications() {
      return this.$store.getters['plot/listCommercialClassification']
    },
   residentialClassifications() {
     return this.$store.getters['plot/listResidentialClassification']
   }
  },
  methods: {
    onSubmit() { /*
      this.create_text = "Creating..."
      let payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password
      }
      this.$store.dispatch('admin/createAdmin', payload)
      .then(() => {
        this.create_text = "Create Admin"
        this.resetForm()
        this.$snotify.success("Account created successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Create Admin"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      }) */
    },
    resetForm() {
      this.plot = ""
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    switchSubClass() {
      if(this.dataClassification === "")
        this.subClassifications = []
      else if(this.dataClassification === "Commercial") {
        this.subClassifications = this.commercialClassifications.map(com => {
          return {
            value: com.id,
            text: com.name
          }
        })
      }
      else if(this.dataClassification === "Residential") {
        this.subClassifications = this.residentialClassifications.map(res => {
          return {
            value: res.id,
            text: res.name
          }
        })
      }

    },
    fetchSubClassifications() {
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize
        },
      }
      this.$store.dispatch("plot/fetchSubClassifications", payload)
    }
  },
  created() {
    this.fetchSubClassifications()
  }
}
</script>
